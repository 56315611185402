<template>
  <el-container class="container">
    <el-header class="header"  v-show="!taskInfo.type=='视频' || !taskInfo.type=='课件'" v-if="taskInfo.type !== '编程'">
      <p class="fl">{{taskInfo.title}}</p>
      <div  v-if="taskInfo.type === '上传' "  style="width: 100%;font-size: 14px;">
        <el-row>
          <el-col :span="3" :offset="1">最小上传大小：{{minFileSize}}<span style="margin-left: 10px;">KB</span></el-col>
          <el-col :span="3">最大上传大小：{{maxFileSize}} <span style="margin-left: 10px;">KB</span></el-col>
          <el-col :span="3">支持的上传的格式:<span style="margin-left: 10px;">{{JSON.parse(uploadType).toString()}}</span></el-col>
        </el-row>
      </div>
    </el-header>
    <el-main :class="getStateTaskInfo(taskInfo.type)">
      <!-- taskInfo: {{taskInfo}} -->
      <!-- showCode: {{showCode}} -->
      <template v-if="taskInfo.type === '图文'" >
        <el-row class="content">
          <el-col :span="20">
            <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></div>
          </el-col>
          <!-- <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></div> -->
        </el-row>
      </template>
      <template v-if="taskInfo.type === '上传' ">
        <el-row class="content">
          <el-col :span="20">
            <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></div>
          </el-col>
        </el-row>
      </template>

      <template v-if="taskInfo.type === '外链'">
        <iframe
          :src="taskInfo.sourceContent"
          frameborder="1"
          allowfullscreen="true"
          width="100%"
          height="99%"
          seamless
          sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
      </template>
      <template v-if="taskInfo.type === 'python'">
        <iframe
          :src="taskInfo.sourceContent"
          frameborder="1"
          width="100%"
          allowfullscreen="true"
          ref="leftFrame"
          height="99%"
          seamless
          sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
      </template>
      <template v-if="taskInfo.type === '编程'">
        <preview-question
          :contest_id="contest_id"
          :taskInfo="taskInfo"
        ></preview-question>
      </template>
      <template v-if="taskInfo.type === '测试'">
        <preview-paper :paper_id="paper_id"></preview-paper>
      </template>
      <template v-if="taskInfo.type === 'goc'">
        <!-- <el-row style="margin-top: 20px"> -->
          <iframe
            :src="goSrc"
            frameborder="1"
            width="100%"
            allowfullscreen="true"
            ref="gocFrame"
            id="gocFrame"
            height="99%"
            seamless
            sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
          ></iframe>
        <!-- </el-row> -->
      </template>
      <template v-if="taskInfo.type === 'scratch'">
        <iframe
          ref="leftFrame"
          :src="scratchIde"
          frameborder="1"
          allowfullscreen="true"
          width="100%"
          height="99%"
          seamless
          sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups">
        </iframe>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import previewQuestion from '@/components/research/curriculum-manage/previewQuestion'
import previewPaper from '@/components/research/curriculum-manage/previewPaper'
import { getTaskInfo,getUploadTaskInfo } from '@/api/research/curriculum'
import { showLoading, hideLoading } from '@/utils/loading'
import Prism from "prismjs"; //引入插件

export default {
  components: {
    previewPaper,
    previewQuestion
  },
  data () {
    return {
      scratchIde: process.env.VUE_APP_SCRATCH_IDE,
      taskID: '',
      taskInfo: {},
      contest_id: '', // 题库ID
      paper_id: '', // 试卷ID
      pythonFlag: false,
      minFileSize:0,
      maxFileSize:0,
      uploadType:0,
      goSrc: '',
      showCode: '' // 查看学生端提交的代码
    }
  },
  created () {
    this.taskID = this.$route.query.taskId;
    if (this.$route.query.taskId) {
      console.log(this.taskID);
      this.$nextTick(()=>{
        this.getTaskInfo();
      })
    }
    if (this.$route.query.code) {
      this.showCode = this.$route.query.code
    }
  },

  methods: {
    goBack () {
      if (!this.$route.query.curriculumID) {
        this.$router.go(-1)
      } else {
        this.$router.push({ // 跳转到课程管理页面
          path: '/research/curriculum-details',
          query: {
            curriculumID: this.$route.query.curriculumID
          }
        })
      }
    },
    async getUploadTaskInfo(id) {
      const response =  await getUploadTaskInfo({
        taskId:id
      })
      this.minFileSize = response.body.minSize
      this.maxFileSize = response.body.maxSize
      this.uploadType = response.body.extList
    },
    async getTaskInfo () {
      const res = await getTaskInfo({
        id: this.taskID
      })
      this.taskInfo = { ...res.body }

      if (this.taskInfo.type === 'python') {
        let code = this.taskInfo.sourceContent
        this.taskInfo.sourceContent =process.env.VUE_APP_PYTHON_IDE + "?pagetype=teacher&pagesource=preview"
        // console.log('this.taskInfo.sourceContent', this.taskInfo.sourceContent)
        setTimeout(()=> {
          this.$refs.leftFrame.contentWindow.postMessage({
            code:code
          }, '*')
        }, 1000)
      } else if (this.taskInfo.type === '上传') {
        this.getUploadTaskInfo(this.taskInfo.id)
      } else if (this.taskInfo.type === 'scratch') {
        showLoading()
        setTimeout(()=> {
          this.$refs.leftFrame.contentWindow.postMessage({
            filePath: this.taskInfo.sourceContent,
            pageType:"previewStudentScratch",
          }, '*')
        },1000)

        window.addEventListener('message', function(msg) {
          if(msg.data === "pageLoadOver") {
            hideLoading()
          }
        });
      } else if (this.taskInfo.type === 'goc') {
        let goObj = JSON.parse(res.body.sourceContent)
        let obj = {
          mode: 5,
          win: '010',
          loop: 1,
          time: 3000,
          title: 'GoC',
          path: '',
          cin: ''
        }
        this.isShowGoc = true
        this.goSrc = process.env.VUE_APP_GOC_ADDRESS
        setTimeout(() => {
          this.$refs.gocFrame.contentWindow.postMessage({
            code: Boolean(this.showCode) ? this.showCode : goObj.code,
            type: 'tms'
          }, '*')
        }, 1000)
      }
      if (res.body.sourceId) {
        if (res.body.type === '编程') {
          this.contest_id = res.body.sourceId
        } else if (res.body.type === '测试') {
          this.paper_id = res.body.sourceId
        }
      }

      this.$nextTick(() => {
        Prism.highlightAll()
      })
    },

    getStateTaskInfo (state) {
      switch (state) {
        case '测试' :
          return 'test-main'
        case '编程' :
          return 'bc-main'
        default :
          return 'main'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #e5e5e5;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;
    padding: 0;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: justify;
      color: #616161;
    }
  }
  .bc-main {
    background: #fff;
    width: 100%;
    min-width: 1200px;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  .test-main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }
}

/deep/ .el-page-header__left:hover {
  color: #f5c319;
}

.footer-condition {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: #c1c1c1;
  &:hover {
    color: #f5c319;
    cursor: pointer;
  }
  .el-icon-question {
    display: inline-block;
    margin-right: 5px;
  }
  .condition-con {
    p {
      margin-bottom: 10px;
    }
  }
}
.footer-btn {
  margin-right: 25px;
  color: #212121;
}
</style>
<style>
  p a {
    color: #2ea1f2;
  }
  /* table 样式 */
  table {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  table td,
  table th {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
  }
  table th {
    border-bottom: 2px solid #ccc;
    text-align: center;
  }

  /* blockquote 样式 */
  blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }

  /* code 样式 */
  code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
  }
  pre code {
    display: block;
  }

  /* ul ol 样式 */
  ul, ol {
    margin: 10px 0 10px 20px;
  }
</style>
