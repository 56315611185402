var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _vm.taskInfo.type !== "编程"
        ? _c(
            "el-header",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.taskInfo.type == "视频" ||
                    !_vm.taskInfo.type == "课件",
                  expression:
                    "!taskInfo.type=='视频' || !taskInfo.type=='课件'",
                },
              ],
              staticClass: "header",
            },
            [
              _c("p", { staticClass: "fl" }, [
                _vm._v(_vm._s(_vm.taskInfo.title)),
              ]),
              _vm.taskInfo.type === "上传"
                ? _c(
                    "div",
                    { staticStyle: { width: "100%", "font-size": "14px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 3, offset: 1 } }, [
                            _vm._v("最小上传大小：" + _vm._s(_vm.minFileSize)),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v("KB")]
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 3 } }, [
                            _vm._v(
                              "最大上传大小：" + _vm._s(_vm.maxFileSize) + " "
                            ),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v("KB")]
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 3 } }, [
                            _vm._v("支持的上传的格式:"),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _vm._v(
                                  _vm._s(JSON.parse(_vm.uploadType).toString())
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "el-main",
        { class: _vm.getStateTaskInfo(_vm.taskInfo.type) },
        [
          _vm.taskInfo.type === "图文"
            ? [
                _c(
                  "el-row",
                  { staticClass: "content" },
                  [
                    _c("el-col", { attrs: { span: 20 } }, [
                      _c("div", {
                        staticStyle: { "line-height": "25px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.taskInfo.sourceContent),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "上传"
            ? [
                _c(
                  "el-row",
                  { staticClass: "content" },
                  [
                    _c("el-col", { attrs: { span: 20 } }, [
                      _c("div", {
                        staticStyle: { "line-height": "25px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.taskInfo.sourceContent),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "外链"
            ? [
                _c("iframe", {
                  attrs: {
                    src: _vm.taskInfo.sourceContent,
                    frameborder: "1",
                    allowfullscreen: "true",
                    width: "100%",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "python"
            ? [
                _c("iframe", {
                  ref: "leftFrame",
                  attrs: {
                    src: _vm.taskInfo.sourceContent,
                    frameborder: "1",
                    width: "100%",
                    allowfullscreen: "true",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "编程"
            ? [
                _c("preview-question", {
                  attrs: { contest_id: _vm.contest_id, taskInfo: _vm.taskInfo },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "测试"
            ? [_c("preview-paper", { attrs: { paper_id: _vm.paper_id } })]
            : _vm._e(),
          _vm.taskInfo.type === "goc"
            ? [
                _c("iframe", {
                  ref: "gocFrame",
                  attrs: {
                    src: _vm.goSrc,
                    frameborder: "1",
                    width: "100%",
                    allowfullscreen: "true",
                    id: "gocFrame",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "scratch"
            ? [
                _c("iframe", {
                  ref: "leftFrame",
                  attrs: {
                    src: _vm.scratchIde,
                    frameborder: "1",
                    allowfullscreen: "true",
                    width: "100%",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }